
















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Getter } from "vuex-class"

@Component({
  props: {
    userCreaters: Array
  }
})
export default class DashboardContent extends Vue {
  @Getter('asideConfigs') asideConfigs: Object

  @Prop()
    latestAnno: any
  @Prop()
    userCreaters: any[]
  @Prop()
    info: object
  @Prop()
    keySearchLists: any[]
  @Prop()
    createrTagVisible: boolean
  @Prop()
    projGroups: any[]
  @Prop()
    groupTagVisible: boolean
  // init data
  account: any =  JSON.parse(localStorage.getItem("account"))
  projects: string = JSON.parse(localStorage.getItem('projects'))
  annoCreate: string = ''
  annoContent: string = ''
  introEditable: boolean = false
  annoEditable: boolean = false
  description: string = null
  anno: string = null
  selectedLoading: boolean = false
  newCreater: string = ''
  searchLists: any[] = []
  newGroups: string = ''
  created () {
    this.description = (this.projects as any).description
  }
  get isLeader () {
    return (this.projects as any).role === 'leader'
  }
  // ideal不展示
  get isUserGroup () {
    return (this.asideConfigs as any).user_group
  }
  get usersTags () {
    return this.userCreaters || []
  }
  handleCreaterClose(tag) {
    this.$emit('doDelLeader', tag)
  }
  addCreater() {
    this.newCreater = ''
    this.searchLists = []
    this.$emit('createrTagFlagChange')
    this.$nextTick(() => {
      (this.$refs.saveCreaterTag as any).focus()
    })
  }
  introEdit () {
    this.introEditable = true
    this.$nextTick(() => {
      (this.$refs.desInput as any).$refs.textarea.focus()
    })
  }
  annoEdit () {
    this.annoEditable = true
    this.$nextTick(() => {
      (this.$refs.annoInput as any).$refs.textarea.focus()
    })
  }
  introSave () {
    this.introEditable = false
    let form = {
      name: (this.projects as any).name,
      description: this.description,
      is_sample: (this.projects as any).is_sample
    }
    this.$emit('updateDescriptionInfo', form)
  }
  annoSave () {
    this.annoEditable = false
    this.$emit('updateAnnoInfo', this.annoContent)
  }
  handleUserSelect () {
    // 匹配空格之后的电话号码
    let phone = this.newCreater.split(' ').pop()
    let currentInfo = (this.keySearchLists as any).find((item, i) => {
      return phone === item.phone
    })
    let params = {
      projectId: (this.projects as any).id,
      userId: currentInfo.id
    }
    // 添加用户到当前项目
    this.$emit('addToProject', params)
  }
  // 手机号 | 姓名远程搜索用户
  remoteMethod(query) {
    let params = {
      key: query
    }
    this.$emit('doSearchUser', params)
  }
  @Watch ('latestAnno')
  onLatestAnnoChange (val: any, old: any) {
    if (val !== null) {
      this.annoCreate = val.created_at
      this.annoContent = val.content
    } else {
      this.annoContent = '暂无公告'
    }
  }
  @Watch ('keySearchLists')
  onKeySearchListsChange (val: any, old: any) {
    this.searchLists = val
  }
}
