
























import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter, Action } from 'vuex-class'
import topicContent from '@/components/topicConfig/content.vue'
import globalMixins from '@/mixins/global.ts'

@Component({
  components: {
    topicContent
  },
  mixins: [globalMixins]
})
export default class dashboardIndex extends Vue<globalMixins> {
  @Getter('latestAnno') latestAnno: Object
  @Getter('proDetail') proDetail: Object
  @Getter('updateProjInfo') updateProjInfo: Object
  @Getter('userCreaters') userCreaters: Object[]
  @Getter('keySearchLists') keySearchLists: object[]
  @Action('LatestAnnouncement') LatestAnnouncement: Function
  @Action('projectDetail') projectDetail: Function
  @Action('delLeaderToProject') delLeaderToProject: Function
  @Action('findUserBeLeader') findUserBeLeader: Function
  @Action('addLeaderToProject') addLeaderToProject: Function
  @Action('updateProject') updateProject: Function
  @Action('createAnnounce') createAnnounce: Function
  @Action('projectGroups') projectGroups: Function
  @Action('addProjectGroups') addProjectGroups: Function
  @Action('delProjectGroups') delProjectGroups: Function
  @Getter('projGroups') projGroups: Object[]
  @Getter('asideConfigs') asideConfigs: Object
  // data
  project: any = {}
  loading: boolean = false
  createrTagVisible: boolean = false
  groupTagVisible: boolean = false
  info: object = {}
  account: any =  JSON.parse(localStorage.getItem("account"))
  
  get isLeader () {
    return this.project.role === 'leader'
  }
   // ideal不展示
  get isUserGroup () {
    return (this.asideConfigs as any).user_group
  }
  created () {
    this.project = JSON.parse(localStorage.getItem("projects"))
    this.getAnnouncement()
    this.isLeader && this.isUserGroup && this.getProjGroups()
    this.doSearch()
  }
  getAnnouncement () {
    let params = {projectId : this.project.id}
    this.LatestAnnouncement({ params })
  }
  createrTagFlagChange () {
    this.createrTagVisible = !this.createrTagVisible
  }
  groupTagFlagChange () {
    this.groupTagVisible = !this.groupTagVisible
  }
  doSearch () {
    let params = {projectId : this.project.id}
    this.loading = true
    this.projectDetail({ params }).then(() => {
      this.loading = false
      this.createrTagVisible = false
      this.info = this.proDetail
    }).catch(() => {
      this.loading = false
      this.createrTagVisible = false
    })
  }
  updateAnnoInfo (info) {
    this.loading = true
    let params = {
      projectId: (this.project as any).id,
      content: info
    }
    this.createAnnounce({ params }).then(() => {
      this.loading = false
      this.successMsg('公告更新成功！')
    }).catch(err => {
      this.loading = false
      this.errorMsg(err)
    })
  }
  updateDescriptionInfo (obj) {
    this.loading = true
    let params = {
      projectId: (this.project as any).id,
      ...obj
    }
    let oldProjects = JSON.parse(localStorage.getItem('projects'))
    this.updateProject({ params }).then(() => {
      this.info = Object.assign({}, oldProjects, this.updateProjInfo)
      localStorage.setItem('projects', JSON.stringify(this.info))
      this.loading = false
      this.successMsg('课题简介更新成功！')
    }).catch(err => {
      this.loading = false
      this.errorMsg(err)
    })
  }
  doDelLeader (tag) {
    let params = {
      projectId: (this.project as any).id,
      userId: tag.id
    }
    this.delLeaderToProject({ params }).then(() => {
      this.successMsg('删除创建人成功！')
      // 判断删除的是否是登录者本人
      if (tag.id === this.account.id) {
        this.$router.push('/topic-add')
      } else {
        this.doSearch()
      }
    }).catch(() => {
      this.errorMsg('删除失败，请稍后重试！')
      this.doSearch()
    })
  }
  doSearchUser (params) {
    this.findUserBeLeader({ params })
  }
  addToProject (params) {
    this.addLeaderToProject({ params }).then(() => {
      this.successMsg('新增创建人成功！')
      this.doSearch()
    }).catch(() => {
      this.errorMsg('新增创建人失败，请稍后重试！')
      this.doSearch()
    })
  }
  /**
   * 分组功能
  */
  // 获得当前所有组  
  getProjGroups () {
    let params = {
      projectId: this.project.id
    }
    this.projectGroups({ params })
  }
  // 新增分组
  addProjGroups (value) {
    this.loading = true
    let params = {
      projectId: this.project.id,
      name: value
    }
    this.addProjectGroups({ params }).then(() => {
      this.loading = false
      this.isLeader && this.isUserGroup && this.getProjGroups()
      this.successMsg('新增分组成功！')
    }).catch((error) => {
      this.loading = false
      let errMsg = error.response.data.error.message
      this.errorMsg(errMsg + ',添加分组失败,请稍后重试！')
    })
  }
  // 删除分组
  delProjGroups (tag) {
    this.loading = true
    let params = {
      projectId: this.project.id,
      groupId: tag.id
    }
    this.delProjectGroups({ params }).then(() => {
      this.loading = false
      this.isLeader && this.isUserGroup && this.getProjGroups()
      this.successMsg('删除分组成功！')
    }).catch((error) => {
      this.loading = false
      let errMsg = error.response.data.error.message
      this.errorMsg(errMsg + ', 删除失败!')
    })
  }
}
